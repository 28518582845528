<template>
  <page-layout justify="start">
    <v-col cols="12">
      <current-period-pays-card></current-period-pays-card>
    </v-col>
  </page-layout>
</template>

<script>
import PageLayout from "../../components/Layouts/PageLayout";
import CurrentPeriodPaysCard from "../../components/cards/CurrentPeriodPaysCard";
export default {
  name: "CurrentPayments",
  components: { PageLayout, CurrentPeriodPaysCard },
};
</script>

<style scoped></style>
