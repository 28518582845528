<template>
  <simple-card v-bind="$attrs">
    <template v-slot:title-left>
      <slot name="title-left"></slot>
    </template>
    <template v-slot:title-right>
      <slot name="title-right"></slot>
    </template>
    <template v-slot:content>
      <simple-table v-bind="$attrs">
        <template v-slot:table-footer
          ><slot name="table-footer"></slot
        ></template>
      </simple-table>
    </template>
    <template v-slot:footer>
      <slot name="footer"></slot>
    </template>
  </simple-card>
</template>

<script>
import SimpleCard from "./SimpleCard";
import SimpleTable from "../tables/SimpleTable";

export default {
  name: "SimpleCardWithTable",
  components: { SimpleTable, SimpleCard },
};
</script>

<style scoped></style>
