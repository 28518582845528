<template>
  <v-skeleton-loader
    style="min-height: 300px"
    v-if="needLoadingIndicator"
    type="image"
    v-bind="$attrs"
  ></v-skeleton-loader>
  <simple-card-with-table
    :headers="headers"
    :rows="rows"
    :need-footer="false"
    v-else
  >
    <template v-slot:title-left>История платежей</template>
    <template v-slot:title-right>
      <v-btn color="primary" class="mr-2 mb-2" @click="goToPaymentPage">
        Оплатить
      </v-btn>
    </template>
    <!--    <template v-slot:table-footer>-->
    <!--      <div class="v-data-footer px-4 py-2 justify-end font-weight-semibold">-->
    <!--        ИТОГО: {{paymentsSum}} ₽-->
    <!--      </div>-->
    <!--    </template>-->
    <template v-slot:footer>
      <div class="d-flex flex-column w-full h-full justify-end">
        <div class="h-full d-flex align-center justify-center">
          <div class="error--text text-h6">
            Итого к оплате: <span>{{ total }}</span>
          </div>
        </div>
        <div
          class="d-flex justify-center w-full"
          :class="buttonsContainerDirection"
        >
          <v-btn color="primary" class="mr-2 mb-2"> Оплатить </v-btn>
          <v-btn color="primary" :class="buttonWidthClass">
            Оплатить полный ЕПД
          </v-btn>
        </div>
      </div>
    </template>
  </simple-card-with-table>
</template>

<script>
import SimpleCardWithTable from "./SimpleCardWithTable";
import loadingIndicator from "../../shared/mixins/loadingIndicator";
import { mapGetters, mapState } from "vuex";
import AccrualsService from "../../services/AccrualsService";
import PaymentsService from "../../services/PaymentsService";

export default {
  name: "CurrentPeriodPaysCard",
  mixins: [loadingIndicator],
  components: { SimpleCardWithTable },
  data() {
    return {
      loadingFlag: "get_payments",
      headers: AccrualsService.paymentsHeaders,
    };
  },
  computed: {
    ...mapGetters("account", ["total"]),
    ...mapState("account", ["payments"]),
    buttonsContainerDirection() {
      return this.$vuetify.breakpoint.xs ? "flex-column" : "flex-row";
    },
    rows() {
      return AccrualsService.getPaymentsRows(this.payments);
    },
    paymentsSum() {
      return PaymentsService.getPaymentsSum(this.payments);
    },
    buttonWidthClass() {
      return {
        // 'w-full' : this.isXs
      };
    },
  },
  methods: {
    goToPaymentPage() {
      this.$router.push({ name: "payment" });
    },
  },
};
</script>

<style scoped></style>
